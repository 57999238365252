import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_3b2a349d from 'nuxt_plugin_plugin_3b2a349d' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_axios_3927da0b from 'nuxt_plugin_axios_3927da0b' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_c30bf89a from 'nuxt_plugin_cookieuniversalnuxt_c30bf89a' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_gtm_ce44b0be from 'nuxt_plugin_gtm_ce44b0be' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_pluginutils_3a528b90 from 'nuxt_plugin_pluginutils_3a528b90' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_b443164a from 'nuxt_plugin_pluginrouting_b443164a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_0f99c718 from 'nuxt_plugin_pluginmain_0f99c718' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_pluginstokens5bfb7037_0a521f2b from 'nuxt_plugin_pluginstokens5bfb7037_0a521f2b' // Source: ./plugins.tokens.5bfb7037.ts (mode: 'all')
import nuxt_plugin_pluginsauthdf4092fa_48a9b937 from 'nuxt_plugin_pluginsauthdf4092fa_48a9b937' // Source: ./plugins.auth.df4092fa.js (mode: 'all')
import nuxt_plugin_pluginsrouteHistory659bcb40_4985ff29 from 'nuxt_plugin_pluginsrouteHistory659bcb40_4985ff29' // Source: ./plugins.routeHistory.659bcb40.js (mode: 'all')
import nuxt_plugin_pluginscontext6955cb98_75f125df from 'nuxt_plugin_pluginscontext6955cb98_75f125df' // Source: ./plugins.context.6955cb98.js (mode: 'all')
import nuxt_plugin_pluginscacheclient190d1044_2aab03fd from 'nuxt_plugin_pluginscacheclient190d1044_2aab03fd' // Source: ./plugins.cache.client.190d1044.js (mode: 'client')
import nuxt_plugin_pluginscacheserver5932e966_28b2430e from 'nuxt_plugin_pluginscacheserver5932e966_28b2430e' // Source: ./plugins.cache.server.5932e966.js (mode: 'server')
import nuxt_plugin_portalvue_5cf8a128 from 'nuxt_plugin_portalvue_5cf8a128' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_pluginslogmonitor7628dbab_34d6a893 from 'nuxt_plugin_pluginslogmonitor7628dbab_34d6a893' // Source: ./plugins.log.monitor.7628dbab.ts (mode: 'client')
import nuxt_plugin_pluginslognuxt1a43d79c_0e45a358 from 'nuxt_plugin_pluginslognuxt1a43d79c_0e45a358' // Source: ./plugins.log.nuxt.1a43d79c.ts (mode: 'all')
import nuxt_plugin_deviceplugin_479392d4 from 'nuxt_plugin_deviceplugin_479392d4' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_image_242ed706 from 'nuxt_plugin_image_242ed706' // Source: ./image.js (mode: 'all')
import nuxt_plugin_storyblokclient_48d82333 from 'nuxt_plugin_storyblokclient_48d82333' // Source: ../plugins/storyblok-client (mode: 'all')
import nuxt_plugin_components_6fb0430c from 'nuxt_plugin_components_6fb0430c' // Source: ../plugins/components (mode: 'all')
import nuxt_plugin_cmsComponents_a58d64ee from 'nuxt_plugin_cmsComponents_a58d64ee' // Source: ../plugins/cmsComponents (mode: 'all')
import nuxt_plugin_helpers_16d71a4f from 'nuxt_plugin_helpers_16d71a4f' // Source: ../plugins/helpers (mode: 'all')
import nuxt_plugin_richtextrenderer_22939428 from 'nuxt_plugin_richtextrenderer_22939428' // Source: ../plugins/rich-text-renderer (mode: 'all')
import nuxt_plugin_formatdate_1544ba8e from 'nuxt_plugin_formatdate_1544ba8e' // Source: ../plugins/format-date (mode: 'all')
import nuxt_plugin_formatcurrency_607d6a1e from 'nuxt_plugin_formatcurrency_607d6a1e' // Source: ../plugins/format-currency (mode: 'all')
import nuxt_plugin_touchEvents_0de54002 from 'nuxt_plugin_touchEvents_0de54002' // Source: ../plugins/touchEvents (mode: 'all')
import nuxt_plugin_scrollLock_e3689ce4 from 'nuxt_plugin_scrollLock_e3689ce4' // Source: ../plugins/scrollLock (mode: 'all')
import nuxt_plugin_validate_c33f42e8 from 'nuxt_plugin_validate_c33f42e8' // Source: ../plugins/validate (mode: 'all')
import nuxt_plugin_network_54b08df8 from 'nuxt_plugin_network_54b08df8' // Source: ../plugins/network (mode: 'all')
import nuxt_plugin_routechangetrackingobserver_777edf6e from 'nuxt_plugin_routechangetrackingobserver_777edf6e' // Source: ../plugins/route-change-tracking-observer (mode: 'client')
import nuxt_plugin_brazesdk_555b0933 from 'nuxt_plugin_brazesdk_555b0933' // Source: ../plugins/braze-sdk (mode: 'client')
import nuxt_plugin_optimizely_169786a0 from 'nuxt_plugin_optimizely_169786a0' // Source: ../modules/optimizely/plugins/optimizely.context (mode: 'all')
import nuxt_plugin_openproducts_3d8c55d1 from 'nuxt_plugin_openproducts_3d8c55d1' // Source: ../plugins/open-products (mode: 'all')
import nuxt_plugin_gtm_5e4639ea from 'nuxt_plugin_gtm_5e4639ea' // Source: ../plugins/gtm (mode: 'client')
import nuxt_plugin_meta_473eda22 from 'nuxt_plugin_meta_473eda22' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":(title) => {
      if (title?.endsWith('|')) {
        return `${title} ABOUT YOU Outlet`;
      }

      return title || 'ABOUT YOU Outlet';
    },"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"version","content":"36bd50d9"},{"hid":"description","name":"description","content":""},{"name":"robots","content":"index, follow"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"script":[{"innerHTML":"window.dataLayer = window.dataLayer || []","type":"text\u002Fjavascript"},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-T4GWBCV')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-T4GWBCV&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_3b2a349d === 'function') {
    await nuxt_plugin_plugin_3b2a349d(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3927da0b === 'function') {
    await nuxt_plugin_axios_3927da0b(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_c30bf89a === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_c30bf89a(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_ce44b0be === 'function') {
    await nuxt_plugin_gtm_ce44b0be(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_3a528b90 === 'function') {
    await nuxt_plugin_pluginutils_3a528b90(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_b443164a === 'function') {
    await nuxt_plugin_pluginrouting_b443164a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_0f99c718 === 'function') {
    await nuxt_plugin_pluginmain_0f99c718(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginstokens5bfb7037_0a521f2b === 'function') {
    await nuxt_plugin_pluginstokens5bfb7037_0a521f2b(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsauthdf4092fa_48a9b937 === 'function') {
    await nuxt_plugin_pluginsauthdf4092fa_48a9b937(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsrouteHistory659bcb40_4985ff29 === 'function') {
    await nuxt_plugin_pluginsrouteHistory659bcb40_4985ff29(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginscontext6955cb98_75f125df === 'function') {
    await nuxt_plugin_pluginscontext6955cb98_75f125df(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginscacheclient190d1044_2aab03fd === 'function') {
    await nuxt_plugin_pluginscacheclient190d1044_2aab03fd(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginscacheserver5932e966_28b2430e === 'function') {
    await nuxt_plugin_pluginscacheserver5932e966_28b2430e(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_5cf8a128 === 'function') {
    await nuxt_plugin_portalvue_5cf8a128(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginslogmonitor7628dbab_34d6a893 === 'function') {
    await nuxt_plugin_pluginslogmonitor7628dbab_34d6a893(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginslognuxt1a43d79c_0e45a358 === 'function') {
    await nuxt_plugin_pluginslognuxt1a43d79c_0e45a358(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_479392d4 === 'function') {
    await nuxt_plugin_deviceplugin_479392d4(app.context, inject)
  }

  if (typeof nuxt_plugin_image_242ed706 === 'function') {
    await nuxt_plugin_image_242ed706(app.context, inject)
  }

  if (typeof nuxt_plugin_storyblokclient_48d82333 === 'function') {
    await nuxt_plugin_storyblokclient_48d82333(app.context, inject)
  }

  if (typeof nuxt_plugin_components_6fb0430c === 'function') {
    await nuxt_plugin_components_6fb0430c(app.context, inject)
  }

  if (typeof nuxt_plugin_cmsComponents_a58d64ee === 'function') {
    await nuxt_plugin_cmsComponents_a58d64ee(app.context, inject)
  }

  if (typeof nuxt_plugin_helpers_16d71a4f === 'function') {
    await nuxt_plugin_helpers_16d71a4f(app.context, inject)
  }

  if (typeof nuxt_plugin_richtextrenderer_22939428 === 'function') {
    await nuxt_plugin_richtextrenderer_22939428(app.context, inject)
  }

  if (typeof nuxt_plugin_formatdate_1544ba8e === 'function') {
    await nuxt_plugin_formatdate_1544ba8e(app.context, inject)
  }

  if (typeof nuxt_plugin_formatcurrency_607d6a1e === 'function') {
    await nuxt_plugin_formatcurrency_607d6a1e(app.context, inject)
  }

  if (typeof nuxt_plugin_touchEvents_0de54002 === 'function') {
    await nuxt_plugin_touchEvents_0de54002(app.context, inject)
  }

  if (typeof nuxt_plugin_scrollLock_e3689ce4 === 'function') {
    await nuxt_plugin_scrollLock_e3689ce4(app.context, inject)
  }

  if (typeof nuxt_plugin_validate_c33f42e8 === 'function') {
    await nuxt_plugin_validate_c33f42e8(app.context, inject)
  }

  if (typeof nuxt_plugin_network_54b08df8 === 'function') {
    await nuxt_plugin_network_54b08df8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_routechangetrackingobserver_777edf6e === 'function') {
    await nuxt_plugin_routechangetrackingobserver_777edf6e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_brazesdk_555b0933 === 'function') {
    await nuxt_plugin_brazesdk_555b0933(app.context, inject)
  }

  if (typeof nuxt_plugin_optimizely_169786a0 === 'function') {
    await nuxt_plugin_optimizely_169786a0(app.context, inject)
  }

  if (typeof nuxt_plugin_openproducts_3d8c55d1 === 'function') {
    await nuxt_plugin_openproducts_3d8c55d1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_5e4639ea === 'function') {
    await nuxt_plugin_gtm_5e4639ea(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_473eda22 === 'function') {
    await nuxt_plugin_meta_473eda22(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
